import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import { IUser, IUserRequest } from "dto/user.dto";

const urlBase = process.env.REACT_APP_BACKEND_URL;

const route = `${urlBase}/api`;
const local = "http://localhost:5003/olab-46253/us-central1/app/api";

class User {
  async get () {
    const data = await axios.get(
      `${route}/users/pagined/0/20000/?user_rol=Administrador`
    );
    return data?.data ?? [];
  };

  async post (user: IUser) {
    await firebase.database().ref(`users/${user.nit}/`).set(user);
    return true;
  };

  async update (nit: string, req: IUserRequest) {
    await firebase.database().ref(`/users/${nit}/`).update(req);
    return true;
  };
  
  async patchUserByNit(nit:string, body:object) {
    try {
      const { data } = await axios.patch(`${route}/user/${nit}`, body);
      return data;
    } catch (error) {
      console.log("error: ", error);
      return 
    }
  }
  

  async getUserByNit (nit: string) {
    try {
      const { data } = await axios.get(`${route}/user/nit/${nit}`);
      return data[0];
    } catch (error) {
      return undefined;
    }
  };

  async getUserByEmail (email: string) {
    if (!email) return undefined;
    const { data } = await axios.get(`${route}/user/email/${email}`);
    return data[0];
  };

  async getUserByPhone (phone: string) {
    const data = await axios.get(`${route}/user/phone/${phone}`);
    return data?.data ?? [];
  };

  async changePasswordByEmail(email: string, newPassword: string) {
    const response =  await axios.patch(`${route}/change-password`, {
      email,
      newPassword,
    });
    return response?.data;
  }

  async getUserCreationTimeByEmail(email: string) {
    const data = await await axios.get(`${local}/user-creation-time/${email}`);
    return data?.data;
  }
}

const user = new User();
const UserClass = Object.freeze(user);
export default UserClass;
